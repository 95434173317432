// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "@src/configs/API";

export const getData = createAsyncThunk("auth/getData", async () => {
  const response = await API.get("/auth/me");
  return {
    firstname: response.data.firstname,
    lastname: response.data.lastname,
  };
});

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    firstname: "",
    lastname: "",
  },
  reducers: {},
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
