// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";

const rootReducer = {
  auth,
  navbar,
  layout,
};

export default rootReducer;
