// ** Importing 'axios' for requesting API
import axios from "axios";

// ** Toastify
import { toast } from "react-toastify";
import Toast from "./Toast";

// ** Configuration
import { Config } from "./globalConfig";

// Global API URL
export const RootUrl = Config.Urls.API;

// Global Metrics URL
export const MetricsUrl = Config.Urls.Metrics;

// Global REPO URL
export const RepoUrl = Config.Urls.Repo;

// Global Installer URL
export const DownloadInstallerUrl = `${RootUrl}/files/InsaneRMMInstaller.exe`;

// Keys names
const TokenKeyName = "X-Connect-Token";
const UserDataKeyName = "X-Connect-UserData";
const UserDataIDPrefix = "I_";
const UserDataTokenPrefix = "T_";
const UserOrgKeyName = "X-Connect-Organisation";

// Stores functions
export const storeUserToken = (token) => localStorage.setItem(TokenKeyName, token);
export const storeUserData = (data) => {
  localStorage.setItem(UserDataKeyName, JSON.stringify(data));
  data.orgs.forEach((element) => {
    localStorage.setItem(`${UserDataIDPrefix}${element.id}`, element.name);
    localStorage.setItem(`${UserDataTokenPrefix}${element.token}`, element.name);
  });
};
export const storeUserOrg = (organisation) => {
  localStorage.setItem(UserOrgKeyName, JSON.stringify(organisation));
};

// Get functions
const getUserToken = () => localStorage.getItem(TokenKeyName);
export const getUserOrg = () => {
  const data = localStorage.getItem(UserOrgKeyName);
  return JSON.parse(data);
};
export const getUserData = () => {
  const data = localStorage.getItem(UserDataKeyName);
  return JSON.parse(data);
};
export const editLocalUserAvatar = (avatar) => {
  const data = localStorage.getItem(UserDataKeyName);
  data.avatar = avatar;
  localStorage.setItem(UserDataKeyName, JSON.stringify(data));
};

// GetOrgName retourne le nom de l'organisation en prenant son ID en paramètre
export const getOrgName = (id) => {
  const data = localStorage.getItem(`${UserDataIDPrefix}${id}`);
  if (data) {
    return data;
  } else {
    return "Toutes les organisations";
  }
};

// GetOrgName retourne le nom de l'organisation en prenant son ID en paramètre
export const getOrgNameByToken = (token) => {
  const data = localStorage.getItem(`${UserDataTokenPrefix}${token}`);
  if (data) {
    return data;
  } else {
    return "Toutes les organisations";
  }
};

// getOrgIDByToken retourne l'id de l'organisation en prenant son token en paramètre
export const getOrgIDByToken = (token) => {
  const data = getUserData();
  for (const org of data.orgs) {
    if (org.token === token) {
      return org.id;
    }
  }
  return "";
};

// getOrgTokenByID retourne le token de l'organisation en prenant son ID en paramètre
export const getOrgTokenByID = (id) => {
  const data = getUserData();
  for (const org of data.orgs) {
    if (org.id === id) {
      return org.token;
    }
  }
  return "";
};

// Del functions
const delUserToken = () => localStorage.removeItem(TokenKeyName);
const delUserData = () => {
  getUserData().orgs.forEach((element) => {
    localStorage.removeItem(`${UserDataIDPrefix}${element.id}`);
    localStorage.removeItem(`${UserDataTokenPrefix}${element.token}`);
  });
  localStorage.removeItem(UserDataKeyName);
};

// Struct for axios with preregistered X-Connect-Token and URL
export const API = axios.create({
  baseURL: RootUrl,
  headers: {
    "Content-Type": "application/json",
    "X-Connect-Token": getUserToken(),
  },
});

// Function to logout user and remove localstorage
export const logoutUser = () => {
  API.get("/auth/logout");
  delUserData();
  delUserToken();
};

// Function to handle error return
export const handleErrorAPI = (err, hideNotif) => {
  if (!err) return;

  if (err?.response?.status === 401) {
    logoutUser();
    window.location.href = `/login/expire?from=${window.location.pathname ?? ""}`;
    return;
  }

  if (!hideNotif && err.response) {
    const message = err?.response?.data.message ?? "Erreur inconnue";
    toast.error(<Toast message={message} title="Erreur" />, {
      icon: true,
      autoClose: 2800,
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: false,
    });
  }
};

// Function which validate a UserToken
export const validUser = () => {
  const userData = getUserData();
  if (userData === undefined) {
    return false;
  }
  return userData !== null && userData.email !== null && userData.email !== "";
};

// Function wich validate spécifique path.
export const validUserPath = (path) => {
  const userData = getUserData();
  let valid = false;
  if (userData === undefined || path === undefined) {
    return false;
  }
  userData.paths.forEach((element) => {
    if (element === path) {
      valid = true;
    }
  });
  return valid;
};

export default API;
